<template>
  <div class="page" :loaded="Boolean(page) && Boolean(networks)">
   <section class="top-section" v-if="page && page.top">
      <div class="wrapper">
        <div class="container" :style="{ backgroundImage: `url(${page.top.image})` }">
          <div class="content">
            <div class="logos">
              <img src="@/assets/images/wb_logo_ebn-w.svg" alt="EUBIC" class="logo">
              <img src="@/assets/images/wb_logo_irishbics.svg" alt="IrishBICs" class="logo">
            </div>
            <h1 v-html="page.top.title.replace(/\n/g, '<br>')"></h1>
            <h3 v-html="page.top.subtitle.replace(/\n/g, '<br>')"></h3>
            <router-link class="btn white" to="/contact">Contact us</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section networks" v-if="page && page.top && networks">
      <div class="wrapper">
        <div class="section-title centered">
          <h3>Irish BICs Network</h3>
        </div>
        <div class="container">
          <a
            class="network"
            v-for="network in networks"
            :key="network.id"
            :href="network.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="network.image" :alt="network.title" class="network-image">
            <div class="network-title">{{network.title}}</div>
          </a>
        </div>
        <div class="section-footer">
          <p v-html="page.top.description.replace(/\n/g, '<br>')"></p>
          <router-link class="btn medium" to="/contact">Get in touch!</router-link>
        </div>
      </div>
    </section>

    <section class="section image-text"  v-if="page && page.eu">
      <div class="wrapper">
        <div class="container">
          <img :src="page.eu.image" alt="" class="image">
          <div class="content">
            <h3 v-html="page.eu.title.replace(/\n/g, '<br>')"></h3>
            <h5 v-html="page.eu.subtitle.replace(/\n/g, '<br>')"></h5>
            <p v-html="page.eu.description.replace(/\n/g, '<br>')"></p>
            <!-- <router-link class="btn medium" to="/contact">Contact us</router-link> -->
          </div>
        </div>
      </div>
    </section>

    <section class="section video" v-if="page && page.video">
      <div class="wrapper">
        <div class="section-title centered">
          <h3>{{ page.video.title }}</h3>
          <h5>Download the resources</h5>
        </div>
        <div class="downloads" v-if="files.length">
          <div v-for="file in files" :key="file.id" class="file">
            <div class="ic ic-file"></div>
            <div class="file-title">{{file.title}}</div>
            <a :href="file.url" target="_blank" class="btn medium">Download</a>
          </div>
        </div>
        <div class="iframe" v-if="page.video.link">
          <iframe class="ql-video" frameborder="0" allowfullscreen="true" :src="page.video.link"></iframe>
          <div class="thumbnail" v-if="!playVideo">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";

export default {
  name: "Network",
  components: {
    Icon,
  },
  data() {
    return {
      page: null,
      networks: null,
      playVideo: false,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    files() {
      if (!this.page) {
        return [];
      }
      const files = [...this.page.video.files];
      return files.sort((a, b) => a.created_at - b.created_at);
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
  },
  methods: {
    getItems() {
      if (this.pageRef) this.pageRef();
      if (this.networksRef) this.networksRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("networks")
        .onSnapshot((page) => {
          const data = page.data();
          this.page = {
            ...data,
            video: {
              ...data.video,
              files: data.video.files.map((file) => ({
                ...file,
                created_at: file.created_at.toDate(),
              })),
            },
          };
        });
      this.networksRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}networks`)
        .orderBy("order")
        .onSnapshot((networks) => {
          this.networks = networks.docs.map((doc) => doc.data());
        });
    },
    openVideo() {
      setTimeout(() => {
        if (document.activeElement.tagName === "IFRAME") {
          this.playVideo = true;
        }
      });
    }
  },
  mounted: function () {
    this.getItems();
    window.addEventListener("blur", this.openVideo, { once: true });
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.networksRef) this.networksRef();
    window.removeEventListener("blur", this.openVideo, { once: true });
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 64px;
  line-height: 1.166666;
  color: var(--Metallic-Seaweed);
}
.section-title h5 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 40px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.section-footer {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding: 0 96px;
}
.section-footer p::after {
  content: '';
  display: block;
  width: 587px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px auto 40px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  background-image: url(../assets/images/wb_bg_services-header.png);
  border-radius: 12px;
  overflow: hidden;
  padding-top: 53.4%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 64px 96px;
}
.top-section .container .content .logos {
  display: flex;
  position: absolute;
  top: 64px;
  left: 96px;
  gap: 32px;
}
.top-section .container .content .logo {
  height: 72px;
}
.top-section .container .content h1 {
  font-size: 128px;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 64px;
  margin-bottom: 32px;
  max-width: 1050px;
  line-height: 1.1625;
}
.top-section .container .content p {
  font-size: 18px;
}

.text-section .text-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
}
.text-section .text-container .text-aside {
  width: 42%;
  padding: 0 48px;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.16666666;
}
.text-section .text-container .text-paragraph {
  width: 58%;
  padding: 0 48px;
  font-size: 24px;
  font-weight: 500;
}
.text-section .text-container .text-paragraph p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 40px 0;
}

.image-text .container {
  display: flex;
}
.image-text .image {
  width: 580px;
  object-fit: cover;
  border-radius: 12px;
}
.image-text .content {
  flex-grow: 1;
  padding: 48px 96px;
}
.image-text h3 {
  font-size: 64px;
  line-height: 1.166666;
  margin-bottom: 24px;
  color: var(--Metallic-Seaweed);
}
.image-text h5 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.166666;
  color: var(--Dark-Blue);
  margin-bottom: 32px;
}
.image-text p {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
}
.image-text p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px 0;
}

.networks .container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 56px;
}
.networks .network {
  display: flex;
  width: calc(25% - 12px);
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  color: var(--Dark-Blue);
  transition: all 0.2s ease-out;
}
.networks .network:hover {
  box-shadow: 0 24px 32px rgba(63, 61, 86, 0.08);
}
.networks .network img {
  display: block;
  height: 222px;
  margin-bottom: 16px;
}

.video .iframe {
  position: relative;
  padding-top: 56.25%;
  border-radius: 12px;
  overflow: hidden;
}
.video .iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}
.video .iframe .thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(../assets/images/wb_bg_video.jpg) no-repeat center/cover;
  color: #fff;
  pointer-events: none;
  padding: 24px;
}
.video .iframe .thumbnail::before {
  content: '';
  display: block;
  width: 96px;
  height: 96px;
  background: url(../assets/images/wb_ic_play-button.svg) no-repeat center/contain;
}
.video .iframe .thumbnail h4 {
  font-size: 64px;
  margin-top: 40px;
  line-height: 1;
}
.video .downloads {
  display: flex;
  gap: 64px 32px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 104px;
}
.video .downloads .file {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.333333% - 22px);
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 24px 32px 0px rgba(14, 58, 90, 0.08);
  padding: 0 24px 48px;
  text-align: center;
}
.video .downloads .file .ic-file {
  width: 48px;
  height: 48px;
  background-image: url(../assets/images/ic-file.svg);
  margin-top: -24px;
}
.video .downloads .file .file-title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 24px;
}
.video .downloads .file .file-title::after {
  content: '';
  display: block;
  width: 232px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 16px auto 24px;
}

@media screen and (max-width: 1280px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 64px;
  }
  .section-title h3 {
    font-size: 48px;
  }
  .top-section .container {
    min-height: 600px;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content .logo {
    width: 200px;
    left: 64px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 96px;
  }
  .top-section .container .content h3 {
    font-size: 48px;
  }
  .text-section .text-container {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside {
    font-size: 28px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 21px;
  }
  .image-text h3 {
    font-size: 48px;
  }
  .image-text .image {
    width: 50%;
  }
  .image-text .content {
    padding: 64px;
  }
  .image-text h5 {
    font-size: 28px;
  }
  .image-text h5 >>> br {
    display: none;
  }
  .video .iframe .thumbnail h4 {
    font-size: 48px;
  }
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 880px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 48px;
  }
  .section-title h3 {
    font-size: 40px;
  }
  .section-title h5 {
    font-size: 24px;
  }
  .section-footer {
    padding: 0;
  }
  .top-section {
    margin-bottom: 48px;
  }
  .top-section .container .content {
    padding: 48px;
  }
  .top-section .container .content .logo {
    width: 170px;
    left: 48px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 72px;
  }
  .top-section .container .content h3 {
    font-size: 32px;
  }
  .text-section .text-container {
    display: block;
    padding: 0 24px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    width: auto;
  }
  .text-section .text-container .text-aside {
    margin-bottom: 24px;
  }
  .image-text .container {
    display: block;
  }
  .image-text .image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
  .image-text .content {
    padding: 24px 48px 0;
  }
  .networks .network {
    width: calc(50% - 8px);
  }
  .video .iframe .thumbnail h4 {
    font-size: 32px;
    text-align: center;
    margin-top: 24px;
  }
  .video .downloads .file {
    width: calc(50% - 16px);
  }
}

@media screen and (max-width: 580px) {
  .section-title {
    padding: 0;
  }
  .section-footer {
    font-size: 21px;
  }
  .top-section .container .content {
    padding: 32px;
  }
  .top-section .container .content h1 {
    font-size: 48px;
  }
  .top-section .container .content h3 {
    font-size: 32px;
  }
  .text-section .text-container {
    padding: 0;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0;
  }
  .text-section .text-container .text-aside {
    font-size: 21px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 18px;
  }
  .image-text h3 {
    font-size: 32px;
  }
  .image-text h3 >>> br {
    display: none;
  }
  .image-text h5 {
    font-size: 24px;
  }
  .image-text p {
    font-size: 21px;
  }
  .image-text .content {
    padding: 24px 0 0;
  }
  .video .iframe .thumbnail h4 {
    font-size: 24px;
  }
  .video .downloads .file {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .networks .network {
    width: 100%;
  }
}
</style>
